<template>
  <div>
    <h5 class="d-inline-block">
      {{ title }}
    </h5>
    <transition name="fade">
      <el-button
          @click="openForm"
          class="d-inline-block float-right"
          size="mini"
          type="success"
          v-if="!isFormOpened"
      >
        <font-awesome-icon icon="plus" />&nbsp;
                                         {{ $t('system.add') }}
      </el-button>
    </transition>
    <transition name="fade">
      <div v-if="isFormOpened">
        <el-input
            :class="{'validate-error': !!backendErrors.content}"
            :disabled="loading"
            :placeholder="`${$t('system.new_note')}...`"
            autofocus
            autosize
            ref="createNoteInput"
            size="mini"
            type="textarea"
            v-model="content"
        />
        <div class="d-flex justify-content-end mt-2">
          <el-button
              :loading="loading"
              @click="save"
              size="mini"
              type="success"
          >
            {{ $t('system.save') }}
          </el-button>
          <el-button
              :disabled="loading"
              @click="closeForm"
              size="mini"
          >
            {{ $t('system.cancel') }}
          </el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import notification from '@/notification/notify';

export default {
  name: 'WorkOrdersIssueCreateForm',
  props: {
    title: {
      type: String,
    },
    workOrderId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    isFormOpened: false,
    content: '',
    loading: false,
    backendErrors: {},
  }),
  methods: {
    openForm() {
      this.isFormOpened = true;
      this.backendErrors = {};
    },
    closeForm() {
      this.isFormOpened = false;
      this.content = '';
      this.loading = false;
      this.backendErrors = {};
    },
    async save() {
      try {
        this.loading = true;
        const {data} = await axios.post(`workorders/order/${this.workOrderId}/task`, {
          content: this.content,
        });
        this.closeForm();
        this.$emit('created', data);
      } catch (error) {
        console.log('storeError', error);
        notification.notify(
            this.$t('notify.error'),
            this.$t(error.response.data.content),
            'warning');
        this.backendErrors = error.response.data;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
